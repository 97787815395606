<template>
  <div>
    <v-card>
      <StandardCardHeader
        :go-to-link="'admin-company-list'"
        :title="'Edit'"
        :button-text="'Close'"
        :show-action-button="true"
        :link-button-color="'error'"
        @action="save()"
      />
      <v-card
        flat
        light
        class="rounded-0"
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.name"
                  :rules="[validator.required]"
                  dense
                  outlined
                  :label="$t('Name')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.referent_name"
                  dense
                  outlined
                  :label="$t('Referent')"
                  class="w-full"
                  :rules="[validator.required]"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.address"
                  dense
                  outlined
                  :label="$t('Address')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.zipCode"
                  dense
                  outlined
                  :label="$t('Zip code')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.city"
                  dense
                  outlined
                  :label="$t('City')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.country"
                  dense
                  outlined
                  :label="$t('Country')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.email"
                  dense
                  outlined
                  :label="$t('Email')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.phone"
                  dense
                  outlined
                  :label="$t('Phone')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-text-field
                  v-model="company.companyIdentificationNumber"
                  dense
                  outlined
                  :label="$t('ID number')"
                  class="w-full"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-select
                  v-model="company.status"
                  dense
                  outlined
                  :items="statusOptions"
                  item-value="key"
                  :item-text="item => $t(item.name)"
                  :label="$t('Status')"
                  class="w-full"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="6"
              >
                <v-checkbox
                  v-model="company.isClient"
                  :label="$t('Client')"
                  outlined
                  dense
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col
                v-if="company.isClient"
                cols="12"
                md="6"
                lg="6"
              >
                <router-link
                  :to="{ name: 'admin-payment-list', params: { companyId: $route.params.id } }"
                  class="v-btn secondary pa-2"
                >
                  {{ $t('Payment history') }}
                </router-link>
              </v-col>
            </v-row>
          </v-card-text>
          <StandardCardHeader
            :go-to-link="'admin-company-list'"
            :button-text="'Close'"
            :show-action-button="true"
            :link-button-color="'error'"
            @action="save()"
          />
          <v-form
            ref="form"
            lazy-validation
          >
          </v-form>
        </v-form>
      </v-card>
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{ $t('Data successfully saved') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import axiosIns from '@axios'
import { required } from '@core/utils/validation'
import StandardCardHeader from '@/components/shared/StandardCardHeader.vue'

export default {
  components: {
    StandardCardHeader,
  },
  data() {
    const userErrorMessage = null
    const user = { company_id: this.$route.params.id }
    const totalUsers = 0
    const userListTable = []
    const contact = {}
    const showUserModal = false
    const userSelectedRows = []
    const loadingUsers = true
    const optionUsers = {}
    const { companyTypes } = this.$store.state

    const showAddressModal = false
    const contactSelectedRows = []
    const totalContacts = 0
    const contactListTable = []
    const loadingContacts = true
    const optionContacts = {}

    const snackbar = false
    const company = {
      name: null,
      email: null,
      phone: null,
      address: null,
      addressAdditional: null,
      zipCode: null,
      city: null,
      state: null,
      country: null,
      companyIdentificationNumber: null,
      companyIdentificationType: null,
      status: null,
      companyType: null,
      isClient: false,
    }

    const statusOptions = this.$store.state.baseStatuses

    const contactTableColumns = [
      {
        text: this.$t('Name'),
        value: 'name',
      },
      {
        text: this.$t('Address'),
        value: 'address',
      },
      {
        text: this.$t('Zip code'),
        value: 'zip_code',
      },
      {
        text: this.$t('City'),
        value: 'city',
      },
      {
        text: this.$t('Email'),
        value: 'main_email_address',
      },
      {
        text: this.$t('Phone'),
        value: 'phone',
      },
      {
        text: this.$t('Type'),
        value: 'address_type',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    const userTableColumns = [
      {
        text: this.$t('First name'),
        value: 'first_name',
      },
      {
        text: this.$t('Last name'),
        value: 'last_name',
      },
      {
        text: this.$t('Email'),
        value: 'email',
      },
      {
        text: this.$t('Phone'),
        value: 'phone',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    return {
      userErrorMessage,
      companyTypes,
      user,
      totalUsers,
      userListTable,
      contact,
      showAddressModal,
      showUserModal,
      contactSelectedRows,
      userSelectedRows,
      snackbar,
      optionContacts,
      optionUsers,
      loadingContacts,
      loadingUsers,
      company,
      totalContacts,
      contactListTable,
      statusOptions,
      contactTableColumns,
      userTableColumns,
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileDocumentEditOutline,
      },
      validator: {
        required,
      },
    }
  },
  watch: {
    showAddressModal() {
      if (!this.showAddressModal) {
        this.contact = {}
      }
    },
    showUserModal() {
      if (!this.showUserModal) {
        this.user = {}
      }
    },
    optionContacts: {
      handler() {
        this.getCompanyContacts()
      },
      deep: true,
    },
    optionUsers: {
      handler() {
        this.getCompanyUsers()
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    newAddressAdded() {
      this.getCompanyContacts()
      this.contact = {}
    },

    getDataFromApi() {
      axiosIns.get(`/admin/companies/${this.$route.params.id}`)
        .then(res => {
          const { company } = res.data
          this.company.name = company.name
          this.company.referent_name = company.referent_name
          this.company.email = company.email
          this.company.phone = company.phone
          this.company.address = company.address
          this.company.addressAdditional = company.address_additional
          this.company.zipCode = company.zip_code
          this.company.city = company.city
          this.company.state = company.state
          this.company.country = company.country
          this.company.companyIdentificationNumber = company.company_identification_number
          this.company.companyIdentificationType = company.company_identification_type
          this.company.status = company.status
          this.company.companyType = company.company_type
          this.company.isClient = company.is_client
        })
        .catch()
    },

    getCompanyContacts() {
      this.loadingContacts = true
      axiosIns.get(`/admin/companies/${this.$route.params.id}/contacts?page=${this.optionContacts.page}&perPage=
      ${this.optionContacts.itemsPerPage}`)
        .then(res => {
          this.totalContacts = res.data.contacts.total
          this.contactListTable = res.data.contacts.data
          this.loadingContacts = false
        })
        .catch()
    },

    getCompanyUsers() {
      this.loadingUsers = true
      axiosIns.get(`/admin/company-users?companyId=${this.$route.params.id}&page=${this.optionUsers.page}&perPage=
      ${this.optionUsers.itemsPerPage}`)
        .then(res => {
          this.totalUsers = res.data.users.total
          this.userListTable = res.data.users.data
          this.loadingUsers = false
        })
        .catch()
    },

    save() {
      if (this.$refs.form.validate()) {
        const company = {
          name: this.company.name,
          referent_name: this.company.referent_name,
          email: this.company.email,
          phone: this.company.phone,
          address: this.company.address,
          address_additional: this.company.addressAdditional,
          zip_code: this.company.zipCode,
          city: this.company.city,
          state: this.company.state,
          country: this.company.country,
          company_identification_number: this.company.companyIdentificationNumber,
          company_identification_type: this.company.companyIdentificationType,
          status: this.company.status,
          company_type: this.company.companyType,
          is_client: this.company.isClient,
        }

        axiosIns({
          method: 'PUT',
          data: company,
          url: `/admin/companies/${this.$route.params.id}`,
        })
          .then(() => {
            this.snackbar = true
          })
          .catch()
      }
    },
  },
}
</script>
